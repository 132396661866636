import { FC } from 'react';

import { Outlet } from 'react-router-dom';

const Layout: FC = (): JSX.Element => {
	return (
		<>
			<h1>Test</h1>
			<Outlet />
		</>
	);
};

export default Layout;
