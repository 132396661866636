import Layout from 'Layout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import styles from './App.module.scss';

const App = (): JSX.Element => {
	return (
		<main className={styles.appContainer}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						{/* <Route path="completion" element={<CompletionPage />} /> */}
					</Route>
				</Routes>
			</BrowserRouter>
		</main>
	);
};

export default App;
